@font-face {
  font-family: 'standard_ct__condensed';
  src: url('/fonts/standardct-regularcond-webfont.woff2') format('woff2'),
    url('/fonts/standardct-regularcond-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'standard_ct__expanded';
  src: url('/fonts/standardct-regularextd-webfont.woff2') format('woff2'),
    url('/fonts/standardct-regularextd-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'standard_ct__condensed_2';
  src: url('/fonts/standardct-condensed2-webfont.woff2') format('woff2'),
    url('/fonts/standardct-condensed2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'standard_ct__condensed_3';
  src: url('/fonts/standardct-condensed3-webfont.woff2') format('woff2'),
    url('/fonts/standardct-condensed3-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'standard_ct__condensed_4';
  src: url('/fonts/standardct-condensed4-webfont.woff2') format('woff2'),
    url('/fonts/standardct-condensed4-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'standard_ct__condensed_5';
  src: url('/fonts/standardct-condensed5-webfont.woff2') format('woff2'),
    url('/fonts/standardct-condensed5-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'standard_ct__expanded_2';
  src: url('/fonts/standardct-expanded2-webfont.woff2') format('woff2'),
    url('/fonts/standardct-expanded2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'standard_ct__expanded_3';
  src: url('/fonts/standardct-expanded3-webfont.woff2') format('woff2'),
    url('/fonts/standardct-expanded3-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'standard_ct__expanded_4';
  src: url('/fonts/standardct-expanded4-webfont.woff2') format('woff2'),
    url('/fonts/standardct-expanded4-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* MENU FONT */
@font-face {
  font-family: 'standard_ct__expanded_5';
  src: url('/fonts/standardct-expanded5-webfont.woff2') format('woff2'),
    url('/fonts/standardct-expanded5-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* END */
@font-face {
  font-family: 'standard_ct__expanded_6';
  src: url('/fonts/standardct-expanded6-webfont.woff2') format('woff2'),
    url('/fonts/standardct-expanded6-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
