@custom-media --mobile-portrait-up (min-width: 460px);
@custom-media --mobile-only (max-width: 599px);
@custom-media --tablet-portrait-up (min-width: 600px);
@custom-media --tablet-landscape-down (max-width: 899px);
@custom-media --tablet-landscape-up (min-width: 900px);
@custom-media --desktop-up (min-width: 1200px);
@custom-media --big-desktop-up (min-width: 1800px);
@custom-media --retina-up (min-width: 1921px);

@define-mixin font-family {
  font-family: 'aktiv-grotesk-extended', sans-serif;
  font-style: normal;
}

@define-mixin font-family-title {
  font-family: 'aktiv-grotesk-condensed', sans-serif;
  font-style: normal;
}

@define-mixin font-family-menu {
  font-family: 'standard_ct__expanded_5', sans-serif;
  font-style: normal;
}

@define-mixin font-thin {
  font-weight: 300;
}

@define-mixin font-regular {
  font-weight: 400;
}

@define-mixin font-medium {
  font-weight: 500;
}

@define-mixin font-semi-bold {
  font-weight: 600;
}

@define-mixin font-bold {
  font-weight: 700;
}

@define-mixin font-bolder {
  font-weight: 800;
}

@define-mixin font-boldest {
  font-weight: 900;
}

@define-mixin max-width $size {
  width: 100%;
  max-width: $size;
  margin-left: auto;
  margin-right: auto;
}

@define-mixin fill-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@define-mixin background-overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

@define-mixin svg-size {
  display: block;
  width: 100%;
  height: 100%;
}

@define-mixin down-arrow $size {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  transform: translateY(-50%);
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid var(--grey-mid);
}

@define-mixin placeholder-text $color {
  &::placeholder {
    opacity: 1;
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
}

@define-mixin btn-reset {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
}

@define-mixin separator $bg_color: var(--white) {
  display: block;
  position: relative;
  width: 33px;
  margin: 0 10px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: $bg_color;
  }
}

@define-mixin separator-angle $bg_color: var(--white) {
  display: block;
  width: 24px;
  height: 2px;
  transform: rotate(55deg);
  background-color: $bg_color;
}

@define-mixin separator-angle-reversed $bg_color: var(--white) {
  display: block;
  width: 24px;
  height: 2px;
  transform: rotate(-55deg);
  background-color: $bg_color;
}

@define-mixin breakout-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@define-mixin triangle-icon-right $color: var(--black) {
  position: relative;
  padding-right: 29px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    border-style: solid;
    border-width: 8px 0 8px 18px;
    border-color: transparent transparent transparent $color;

    @media (--tablet-landscape-up) {
      border-width: 10px 0 10px 20px;
    }
  }
}
@define-mixin triangle-icon-left $color: var(--black) {
  position: relative;
  padding-left: 29px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    border-style: solid;
    border-width: 8px 0 8px 18px;
    border-color: transparent transparent transparent $color;

    @media (--tablet-landscape-up) {
      border-width: 10px 0 10px 20px;
    }
  }
}

@define-mixin scrollbar-height $height: 6px {
  &::-webkit-scrollbar {
    height: var(--scrollbar-width);
  }
}

@media (--tablet-portrait-up) {
  .spacing--xxxs {
    padding-top: var(--space-xxxs);
  }

  .spacing--xxs {
    padding-top: var(--space-xxs);
  }

  .spacing--xs {
    padding-top: var(--space-xs);
  }

  .spacing--sm {
    padding-top: var(--space-sm);
  }

  .spacing--md {
    padding-top: var(--space-md);
  }

  .spacing--lg {
    padding-top: var(--space-lg);
  }

  .spacing--xl {
    padding-top: var(--space-xl);
  }

  .spacing--xxl {
    padding-top: var(--space-xxl);
  }

  .spacing--xxxl {
    padding-top: var(--space-xxxl);
  }
}

@keyframes fadeInItems {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@define-mixin animation-fadeInItems $delay: 0s {
  animation-name: fadeInItems;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: $delay;
}

@define-mixin link-underline $color: #333, $bottom: 0 {
  display: table;
  position: relative;

  &::after {
    opacity: 1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: $bottom;
    width: 100%;
    height: 1px;
    background-color: $color;
    transition: opacity 0.2s;
  }

  &:focus {
    outline: none;
  }
}

@define-mixin slider-logo {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 296px;
  height: 54px;
  z-index: var(--z-default);

  @media (--tablet-landscape-up) {
    width: 460px;
    height: 117px;
  }

  svg {
    @mixin svg-size;
  }
}
